import React, { useEffect, useRef, useState } from "react";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	IconButton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CarouselViewer from "../Carousel/CarouselViewer";
import { theme } from "../../../assets/themes/theme";
import { formatText, timeElapsed } from "../../../utils/tool";
import TagsContainer from "../../atoms/TagsContainer/TagsContainer";
import InputComment from "../../atoms/Inputs/InputComment";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useDispatch, useSelector } from "react-redux";
import { cleanDataP, likePost } from "../../../store/slices/postSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const PostComponent = ({
	postId,
	parentWidth,
	setMoreDetails,
	handleOpenOptions,
	context,
}) => {
	const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [isExpanded, setIsExpanded] = useState(false);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [isAuthor, setIsAuthor] = useState(false);
	const contentRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const post = useSelector((state) => {
		switch (context) {
			case "profile":
				return state.posts.profilesItems[id]?.itemsPI?.find(
					(p) => p?.id === postId
				);
			default:
				return state.posts.itemsP?.find((p) => p?.id === postId);
		}
	});

	const { user } = useSelector((state) => state.auth);

	const handleLike = () => {
		if (!post.loadingLike) {
			dispatch(likePost({ postId: post?.id, userId: id }));
		}
	};

	const handleShared = () => {
		navigator.clipboard.writeText(
			"https://goodmove.gregory-mostacci.fr/shared/" + post?.uuid
		);
		toast.success("Copier dans le presse papier. ", {
			toastId: "shared-post-success",
		});
	};

	const handleToggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	const timeElapsedStr = timeElapsed(post?.createdAt);

	const SXBoxContainer = {
		display: "flex",
		ml: "auto",
		mr: "auto",
	};

	const SXCardMediaSmallScreen = {
		width: "100%",
		height: "auto",
	};

	const SXCardMediaWeb = {
		width: "340px",
		height: "272px",
		borderRadius: "10px",
	};

	useEffect(() => {
		const element = contentRef.current;
		if (element) {
			setIsOverflowing(element.scrollHeight > element.clientHeight);
		}
	}, [post?.description]);

	useEffect(() => {
		post && user && setIsAuthor(post?.user?.id === user?.id);
	}, [post, user]);

	const handleReloadFeed = (id) => {
		dispatch(cleanDataP());
		navigate("/feed?tag=" + id);
	};

	return (
		<Card>
			<Box sx={SXBoxContainer}>
				{!smallScreen && (
					<Box ml={2} mt={"auto"} mb={"auto"}>
						{post?.mediaObjects &&
							post?.mediaObjects.length > 0 &&
							(post?.mediaObjects.length === 1 ? (
								<CardMedia
									component="img"
									image={post?.mediaObjects[0].picture_url}
									alt="poste img"
									sx={{
										mt: 2,
										mb: 2,
										...SXCardMediaWeb,
									}}
								/>
							) : (
								<Box width="340px" height="272px" mt={2} mb={2}>
									<CarouselViewer
										images={post?.mediaObjects}
										SXCardMedia={SXCardMediaWeb}
									/>
								</Box>
							))}
					</Box>
				)}
				<Box flex={1} display={"flex"} flexDirection={"column"}>
					<CardHeader
						avatar={
							<Avatar
								src={post?.user?.profilePicture}
								onClick={() =>
									navigate("/profile/" + post?.user?.id)
								}
								sx={{ borderRadius: 1, cursor: "pointer" }}
							/>
						}
						action={
							<IconButton
								id="settingsBtn"
								aria-label="settings"
								aria-describedby={"test"}
								type="button"
								onClick={() =>
									handleOpenOptions(postId, isAuthor)
								}
							>
								<MoreVertIcon />
							</IconButton>
						}
						title={
							post?.user?.firstName + " " + post?.user?.lastName
						}
						subheader={timeElapsedStr}
						sx={{
							pb: 1,
							".MuiCardHeader-title": {
								fontSize: "16px",
								fontWeight: "600",
							},
							".MuiCardHeader-subheader": {
								fontSize: "12px",
								fontWeight: "500",
								color: theme.palette.primary.main,
							},
						}}
					/>
					<CardContent sx={{ pt: 1, pb: 1 }}>
						<Typography
							ref={contentRef}
							variant="body2"
							sx={{
								width: "100%",
								overflow: "hidden",
								overflowWrap: "break-word",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: isExpanded ? "none" : 5,
								lineClamp: isExpanded ? "none" : 5,
								ml: "auto",
								mr: "auto",
								maxWidth: {
									xs: "420px",
									sm: "450px",
									md: "700px",
									lg: "740px",
								},
							}}
						>
							{formatText(post?.description)}
						</Typography>
						{isOverflowing && (
							<Typography
								variant="body2"
								onClick={handleToggleExpand}
								sx={{
									cursor: "pointer",
								}}
							>
								{isExpanded ? "voir moins" : "voir plus..."}
							</Typography>
						)}

						<br />
						{post && post?.tags?.length > 0 && (
							<Box display={"flex"} flexWrap={"wrap"}>
								{post?.tags?.map((tag) => {
									return (
										<Typography
											key={post?.id+"-"+tag?.id}
											onClick={() => {
												handleReloadFeed(tag?.id);
											}}
											sx={{
												fontWeight: 500,
												color: theme.palette.primary
													.main,
												m: 0,
												p: 0,
												cursor: "pointer",
											}}
										>
											#{tag?.name}{" "}
										</Typography>
									);
								})}
							</Box>
						)}
					</CardContent>

					{smallScreen && (
						<Box ml={"auto"} mr={"auto"}>
							{post?.mediaObjects &&
								post?.mediaObjects.length > 0 &&
								(post?.mediaObjects.length === 1 ? (
									<CardMedia
										component="img"
										image={
											post?.mediaObjects[0].picture_url
										}
										alt="poste img"
										sx={SXCardMediaSmallScreen}
									/>
								) : (
									<Box
										position="relative"
										width={parentWidth}
										sx={{
											maxWidth: {
												xs: "450px",
												sm: "480px",
												md: "800px",
												lg: "800px",
											},
										}}
									>
										<CarouselViewer
											images={post?.mediaObjects}
											SXCardMedia={SXCardMediaSmallScreen}
										/>
									</Box>
								))}
						</Box>
					)}
					<Box
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"end"}
						mt={1}
						mr={2}
						ml={2}
						mb={1}
						height={"100%"}
					>
						<Box display={"flex"} justifyContent={"space-between"}>
							<Box display={"flex"}>
								<IconButton
									onClick={handleLike}
									disabled={post?.loadingLike}
								>
									{post?.userHasLiked ? (
										<FavoriteIcon sx={{ color: "red" }} />
									) : (
										<FavoriteIcon />
									)}
								</IconButton>
								<Typography
									variant="body2"
									textAlign={"left"}
									sx={{ mt: "auto", mb: "auto" }}
								>
									{post?.nbLikes} J'aimes
								</Typography>
							</Box>
							<IconButton onClick={handleShared}>
								<ShareOutlinedIcon />
							</IconButton>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box ml={2} mr={2} mb={2}>
				<InputComment postId={postId} userId={id} />
				<Typography
					variant="body2"
					color="secondary.ultradark"
					textAlign={"center"}
					sx={{ ml: "auto", mr: "auto", mt: 1, cursor: "pointer" }}
					onClick={() => setMoreDetails(postId)}
				>
					{post?.nbComments === 0
						? "aucun commentaire"
						: "- " + post?.nbComments + " commentaires -"}
				</Typography>
			</Box>
		</Card>
	);
};

export default PostComponent;
