import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AppRoutes from "./routes/AppRoutes";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "./assets/themes/theme";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, logout } from "./store/slices/authSlice";
import LoadingSection from "./components/organisms/LoadingSection/LoadingSection";
import { fetchUserById } from "./store/slices/userSlice";

const App = () => {
	const isWeb = useMediaQuery(theme.breakpoints.up("lg"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const SXMarginSidebar = () => {
		if (isWeb) {
			return "285px";
		} else if (!isWeb && !isMobile) {
			return "100px";
		} else {
			return "0px";
		}
	};
	const [loadingApp, setLoadingApp] = useState(true);
	const dispatch = useDispatch();
	const { isAuthenticated, user, loading, error } = useSelector(
		(state) => state.auth
	);

	useEffect(() => {
		if (
			(isAuthenticated && !user && !loading && error) ||
			(isAuthenticated && user && !user?.username && loadingApp)
		) {
			dispatch(logout());
		} else if (isAuthenticated && !user && !loading && !error) {
			dispatch(fetchUser());
		} else if (isAuthenticated && user && loadingApp) {
			dispatch(fetchUserById({ userId: user?.id }));
			setLoadingApp(false);
		} else if (!isAuthenticated && loadingApp) {
			setLoadingApp(false);
		}
	}, [user, error]);

	if (loadingApp) {
		return <LoadingSection />;
	}

	return (
		<Box bgcolor={theme.palette.secondary.dark}>
			<AppRoutes SXMarginSidebar={SXMarginSidebar} />
			<ToastContainer
				style={{ width: isMobile?"100%":"auto", minWidth: isMobile?"none":"500px" }}
				position="top-center"
				closeOnClick
				limit={2}
			/>
		</Box>
	);
};

export default App;
