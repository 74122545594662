import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	requestAxiosEditOneUser,
	requestAxiosGetOneUser,
} from "../../services/axios/axiosRequests";

// récupération des données d'un utilisateur
export const fetchUserById = createAsyncThunk(
	"users/fetchOne",
	async ({ userId }, { rejectWithValue }) => {
		try {
			const user = await requestAxiosGetOneUser(userId);
			return { user };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const editUserProfil = createAsyncThunk(
	"users/editUser",
	async (
		{
			userId,
			profilePicture,
			bannerImage,
			lastName,
			firstName,
			birthday,
			bio,
		},
		{ rejectWithValue }
	) => {
		try {
			const updatedUser = await requestAxiosEditOneUser(
				userId,
				profilePicture,
				bannerImage,
				lastName,
				firstName,
				birthday,
				bio
			);
			return { updatedUser };
		} catch (error) {
			return rejectWithValue({ userId, error });
		}
	}
);

// // suppression d'un compte
// export const deleteUser = createAsyncThunk(
// 	// TODO
// 	"users/deleteUsers",
// 	async ({ userId }, { rejectWithValue }) => {
// 		try {
// 			const response = await requestAxiosDeleteAccount({ userId });
// 			return response;
// 		} catch (error) {
// 			return rejectWithValue(error);
// 		}
// 	}
// );

const userSlice = createSlice({
	name: "users",
	initialState: {
		itemsU: [],
		loadingU: false,
		errorU: null,
	},
	reducers: {
		addUsersList: (state, action) => {
			const { users } = action.payload;
			users?.map((user) => {
				const index = state.itemsU.findIndex(
					(itemU) => itemU?.id === user?.id
				);
				if (index === -1) {
					state.itemsU[user?.id] = {
						id: user?.id,
						firstName: user?.firstName,
						lastName: user?.lastName,
						profilePicture: user?.profilePicture,
						backgroundPicture: user?.backgroundPicture,
						bio: user?.bio,
						nbFollowers: user?.nbFollowers,
						nbFollowing: user?.nbFollowing,
						followedSince: user?.followedSince,
						followingSince: user?.followingSince,
						username: user?.username || "unknown",
					};
				}
			});
		},
		addOneFollower: (state, action) => {
			const { userId } = action.payload;
			const index = state.itemsU.findIndex((user) => user?.id == userId);
			if (index !== -1) {
				state.itemsU[index].nbFollowers += 1;
				state.itemsU[index].followedSince = new Date().toISOString();
			}
		},
		addOneFollowing: (state, action) => {
			const { userId } = action.payload;
			const index = state.itemsU.findIndex((user) => user?.id == userId);
			if (index !== -1) {
				state.itemsU[index].nbFollowing += 1;
				state.itemsU[index].followingSince = new Date().toISOString();
			}
		},
		deleteOneFollower: (state, action) => {
			const { userId } = action.payload;
			const index = state.itemsU.findIndex((user) => user?.id == userId);
			if (index !== -1) {
				state.itemsU[index].nbFollowers -= 1;
				state.itemsU[index].followedSince = undefined;
			}
		},
		deleteOneFollowing: (state, action) => {
			const { userId } = action.payload;
			const index = state.itemsU.findIndex((user) => user?.id == userId);
			if (index !== -1) {
				state.itemsU[index].nbFollowing -= 1;
				state.itemsU[index].followingSince = undefined;
			}
		},
		cleanErrorU: (state) => {
			state.errorU = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserById.pending, (state) => {
				state.loadingU = true;
				state.errorU = null;
			})
			.addCase(fetchUserById.fulfilled, (state, action) => {
				const { user } = action.payload;
				state.itemsU[user?.id] = {
					id: user?.id,
					firstName: user?.firstName,
					lastName: user?.lastName,
					profilePicture: user?.profilePicture,
					backgroundPicture: user?.backgroundPicture,
					bio: user?.bio,
					nbFollowers: user?.nbFollowers,
					nbFollowing: user?.nbFollowing,
					followedSince: user?.followedSince,
					username: user?.username || "unknown",
					birthday: user?.birthday,
					loadingUPatch: false,
				};
				state.loadingU = false;
			})
			.addCase(fetchUserById.rejected, (state, action) => {
				state.loadingU = false;
				state.errorU = action.payload;
			})
			.addCase(editUserProfil.pending, (state, action) => {
				const userId = action.meta.arg?.userId;
				state.itemsU[userId].loadingUPatch = true;
				state.errorU = null;
			})
			.addCase(editUserProfil.fulfilled, (state, action) => {
				const { updatedUser } = action.payload;
				state.itemsU[updatedUser?.id] = {
					...state.itemsU[updatedUser?.id],
					...(updatedUser.firstName && {
						firstName: updatedUser.firstName,
					}),
					...(updatedUser.lastName && {
						lastName: updatedUser.lastName,
					}),
					...(updatedUser.profilePicture && {
						profilePicture: updatedUser.profilePicture,
					}),
					...(updatedUser.backgroundPicture && {
						backgroundPicture: updatedUser.backgroundPicture,
					}),
					...(updatedUser.bio && { bio: updatedUser.bio }),
					...(updatedUser.birthday && {
						birthday: updatedUser.birthday,
					}),
					loadingUPatch: false,
				};
			})
			.addCase(editUserProfil.rejected, (state, action) => {
				const userId = action.meta.arg?.userId;
				state.itemsU[userId].loadingUPatch = false;
				state.errorU = action.payload;
				state.errorU.message =
					"Une erreur est survenue lors de la modification de votre profil";
			});
	},
});
export const {
	addUsersList,
	addOneFollower,
	addOneFollowing,
	deleteOneFollowing,
	deleteOneFollower,
	cleanErrorU,
} = userSlice.actions;

export default userSlice.reducer;
